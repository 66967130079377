export const emailTemplate = `
<p>You’ve been invited to join the NGS Aurora Platform.</p>
<p>You’re three quick steps away from being ready to travel!</p>
<ol>
<li>Press the blue ‘Activate Account’ button displayed above to activate your account and enter your details.</li>
<li>Download the NGS Aurora App by pressing one of the blue download buttons listed below. Alternatively, you can type 'NGS Aurora App' into your device’s App Store and press the download button.</li>
<li>Login to the NGS Aurora App, input your email and password and follow the app permissions process.</li>
</ol>
<p>Once the app permissions process is complete, the NGS Aurora App is LIVE.  If you want to test any of the emergency functions, please notify the NGS Operations Center beforehand, otherwise they will treat any activations as a live emergency.</p>


<p>Please use the link below to access the tutorial video for the NGS Aurora App:</p>
<p><a href="https://youtu.be/AcdNB6ra1iM">https://youtu.be/AcdNB6ra1iM</a></p>

<p>If you have any questions please email: <a href="mailto:tracking@northcottglobalsolutions.com">tracking@northcottglobalsolutions.com</a></p>
`
